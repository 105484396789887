import React from "react";
import Header from "../../../../components/header/Header";
import Content from "../../../../components/main/content/content";
import Sidebar from "../../../../components/main/sidebar/Sidebar";
import Footer from "../../../../components/footer/Footer";
import banner from "../images/banner.png";
import banner1 from "../images/banner1.png";
import gif from "../images/booking.png";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";

const ArticlePage = (props) => {
  const { data } = props;
  const { pageContext } = props;
  const { postSearchData } = pageContext;
  const { allPosts, options } = postSearchData;

  const sidebar = {
    banner,
    banner1,
    gif,
    url: "https://www.booking.com/index.html?aid=1819211",
  };

  let temp = data?.cms?.recent_story;
  let featured_post = temp.filter(checkFetured);
  function checkFetured(element) {
    return element.is_feature_post;
  }
  return (
    <div className="layout">
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-WM27LRZQNE"></script>
        <script>
          {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-WM27LRZQNE');
          `}
        </script>
      </Helmet>
      <div className="banner-container">
        <Link to={sidebar?.url}>
          <img src={sidebar?.banner1} alt={"banner"} />
        </Link>
      </div>
      <Header category={data?.cms?.categories} allPosts={allPosts} engine={options} domain={data?.cms?.domain} />
      <div className="container is-max-widescreen">
        <div className="columns m-0">
          <Content story={data?.cms?.page} domain={data?.cms?.domain} />
          <Sidebar recent_story={featured_post} domain={data?.cms?.domain} banner={sidebar} />
        </div>
      </div>
      <div className="banner-container">
        <Link to={sidebar?.url}>
          <img src={sidebar?.banner1} alt={"banner"} />
        </Link>
      </div>
      <Footer pages={data?.cms?.pages} domain={data?.cms?.domain} />
    </div>
  );
};
export default ArticlePage;

export const query = graphql`
  query pages($slug: ID!) {
    cms {
      domain: CMS_Domain(id: "21") {
        id
        logo
        domain_url
        name
        description
        favicon
      }
      categories: CMS_CategoryByDomain(domain_id: "21") {
        name
        slug
      }
      recent_story: CMS_DomainWiseStories(domain_id: "21") {
        image
        slug
        title
        is_feature_post
        categories {
          name
        }
      }
      page: CMS_Page(id: $slug) {
        content
        slug
        title
      }
      pages: Published_Pages(domain_id: "1") {
        slug
        title
      }
    }
  }
`;
